<template>
  <div class="loading">
    <img
      :src="loader"
      class="opacity-7"
      alt="loading"
    >
  </div>
</template>

<script>
import loader from '@/assets/img/content-load.gif'

export default {
  name: 'ContentPlaceholder',
  data() {
    return {
      loader
    }
  }
}
</script>

<style>
.loading img {
  margin: 10px auto;
  height: 100px;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
}
.loading {
  height: 200px;
}

</style>

