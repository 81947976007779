import AbstractResource from '@/resources/abstract.resource'
import { UrlHelper } from '@/utils/urlHelper'

export default class SignupRequestResource extends AbstractResource {
  constructor () {
    super()
  }

  async getPendingSignupRequests (params) {
    try {
      const response = await this.client.get('signup-request/pending', { params: UrlHelper.buildUrlParams(params) })
      return {data: response.data.data, total: response.data.total}
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  async updateSignupRequest (id, signupRequest) {
    try {
      return this.client.patch(`/signup-request/${id}`, signupRequest)
    } catch (error) {
      console.warn(error)
      return null
    }
  }

  async deleteSignupRequest (signupRequestId) {
    try {
      await this.client.delete(`/signup-request/${signupRequestId}`)
      return true
    } catch (error) {
      console.warn(error)
      return null
    }
  }
}
