<template>
  <MenuItemList :menu-section-id="menuSectionId" />
</template>

<script>
import MenuItemList from '@/views/components/menuItem/MenuItemList.vue'

export default {
  name: 'MenuItemsPage',
  components: {
    MenuItemList
  },
  data () {
    const menuSectionId = this.$route.params?.menuSectionId ?  this.$route.params?.menuSectionId : null
    return {
      menuSectionId
    }
  }
}
</script>
