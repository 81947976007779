<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <div class="pb-0 card-header">
          <div class="row">
            <div class="col p-3 d-flex justify-content-center">
              <h1 class="mb-2">
                {{ $t('messages.welcome') }}
              </h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <p class="text-lead text-center">
            {{ $t('messages.thisIsYourPrivateArea') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultPage'
}
</script>

<style scoped>
</style>
