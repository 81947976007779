<template>
  <div class="py-4 container-fluid">
    <div class="row mb-4">
      <ManageSignupRequest
        v-if="signupRequest && showForm"
        :signup-request="signupRequest"
        @update-signup-request="updateItem"
        @delete-signup-request="deleteItem"
        @close-signup-request="closeItem"
      />
      <div class="card">
        <div class="pb-0 card-header">
          <div class="row">
            <div class="col d-flex justify-content-start">
              <h6 class="mb-2">{{ $t('messages.signupRequests') }}</h6>
            </div>
          </div>
        </div>
        <contentPlaceholder v-if="loading" />
        <div
          v-else
          class="table"
        >
          <div class="table-row">
            <div class="px-2 py-1 row">
              <div class="col">
                <p class="mb-0 text-sm font-weight-bold">
                  {{ $t('messages.name') }}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div
              v-for="currentSignupRequest in signupRequests"
              :key="currentSignupRequest.id"
            >
              <div class="table-row">
                <div class="px-2 py-1 row">
                  <div class="col">
                    <h6 class="mb-0 text-sm">
                      {{ currentSignupRequest.name }}
                    </h6>
                  </div>
                  <div class="col">
                    <h6 class="mb-0 text-sm">
                      {{ currentSignupRequest.email }}
                    </h6>
                  </div>
                  <div class="col">
                    <h6 class="mb-0 text-sm">
                      {{ dateHelper.getDate(currentSignupRequest.createdAt) }}
                    </h6>
                  </div>
                  <div class="col">
                    <h6 class="mb-0 text-sm">
                      {{ $t('messages.status.' + currentSignupRequest.status) }}
                    </h6>
                  </div>
                  <div class="col d-flex justify-content-end">
                    <a
                      class="btn btn-link text-success"
                      :title="$t('messages.editTavern')"
                      @click="selectItem(currentSignupRequest)"
                    >
                      <mdicon name="email-open-outline" />
                    </a>
                    <a
                      class="btn btn-link text-danger"
                      :title="$t('messages.deleteSignupRequest')"
                      @click="deleteItem(currentSignupRequest)"
                    >
                      <mdicon name="trash-can-outline" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        v-if="showPagination"
        v-model="paging.page"
        :loading="loading"
        :total-rows="total"
        :per-page="paging.limit"
        class="mt-3 mb-3"
        :value="paging.page"
        @set-page="setPage"
      />
    </div>
  </div>
</template>

<script>
import ContentPlaceholder from '@/components/layout/ContentPlaceholder.vue'
import Pagination from '@/components/common/Pagination.vue'
import SignupRequestResource from '@/resources/signupRequest.resource'
import { DateHelper } from "@/utils/dateHelper";
import ManageSignupRequest from "@/views/components/signupRequest/ManageSignupRequest.vue";
import SignupRequest from "@/models/signupRequest";

export default {
  name: "SignupRequestList" ,
  components: {ManageSignupRequest, Pagination, ContentPlaceholder },
  data () {
    return {
      loading: true,
      paging: { page: 1, limit: 20, sort: 'createdAt', sortType: 'desc' },
      total: null,
      showPagination: false,
      signupRequestResource: new SignupRequestResource(),
      signupRequests: null,
      dateHelper: DateHelper,
      showForm: false,
      signupRequest: null
    }
  },
  mounted () {
    this.getPendingSignupRequests()
  },
  methods: {
    async getPendingSignupRequests () {
      const response = await this.signupRequestResource.getPendingSignupRequests(this.paging)
      if (response) {
        this.loading = false
        this.signupRequests = response.data
        this.total = response.total
      }
    },
    async updateItem (id, signupRequest) {
      try {
        const response = await this.signupRequestResource.updateSignupRequest(id, signupRequest)
        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'))
          return false
        }
        await this.$notice.toast.success(this.$t('messages.updateMessage'))
        this.updateItemOnList(response.data)
      } catch (e) {
        await this.$notice.alert(this.$t('messages.errorMessage'))
      }
      this.closeItem()
    },
    async deleteItem (signupRequest) {
      await this.$notice.confirm(this.$t('messages.areYouSure')).then(async (res) => {
        if (res) {
          try {
            const response = await this.signupRequestResource.deleteSignupRequest(signupRequest.id)
            if (!response) {
              await this.$notice.alert(this.$t('messages.errorMessage'))
              return
            }
            await this.$notice.toast.success(this.$t('messages.deletedMessage'));
            this.removeItemFromList(signupRequest)
          } catch (e) {
            await this.$notice.alert(this.$t('messages.errorMessage'))
          }
        }
      })
      this.closeItem()
    },
    updateItemOnList (signupRequest) {
      this.signupRequests.find((item, index) => {
        if (item.id === signupRequest.id) {
          this.signupRequests[index] = signupRequest
        }
      })
    },
    removeItemFromList (signupRequest) {
      this.signupRequests = this.signupRequests.filter((item) => item.id !== signupRequest.id)
    },
    selectItem (item) {
      this.signupRequest = new SignupRequest(item)
      this.showForm = true
    },
    closeItem () {
      this.signupRequest = null
    },
    setPage (page) {
      this.paging.page = page
      this.selectItems()
    }
  }
}
</script>

<style scoped>
.table :not(:first-child) {
  border: none;
}
.table-row {
  border-bottom: 1px solid #D5D8DC !important;
}
.table-row:hover {
  background-color: #E5E4E2;
}
.col > .btn {
  margin-bottom: 0;
}
</style>
