<template>
  <MenuSectionList :menu-id="menuId" />
</template>

<script>
import MenuSectionList from '@/views/components/menuSection/MenuSectionList.vue'

export default {
  name: 'MenuSectionsPage',
  components: {
    MenuSectionList
  },
  data () {
    const menuId = this.$route.params?.menuId ?  this.$route.params?.menuId : null
    return {
      menuId
    }
  }
}
</script>
