<template>
  <MenuList :tavern-id="tavernId" />
</template>

<script>
import MenuList from '@/views/components/menu/MenuList.vue'

export default {
  name: 'MenusPage',
  components: {
    MenuList
  },
  data () {
    const tavernId = this.$route.params?.tavernId ?  this.$route.params?.tavernId : null
    return {
      tavernId
    }
  }
}
</script>
