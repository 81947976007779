import AbstractResource from '@/resources/abstract.resource'
import { UrlHelper } from '@/utils/urlHelper'

export default class adminTagResource extends AbstractResource {
    constructor () {
        super()
    }
    async createTag (tag) {
        try {
            return this.client.post('/tag', tag)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async updateTag (tag) {
        try {
            return this.client.patch(`/tag/${tag.id}`, tag)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async deleteTag (tagId) {
        try {
            await this.client.delete(`/tag/${tagId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async getMenuItemTags (menuItemId) {
        try {
            const tags = await this.client.get(`/menu-item/${menuItemId}/tags`)
            return tags.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async getTags(params) {
        try {
            const tags = await this.client.get('/tag/', { params: UrlHelper.buildUrlParams(params) })
            return {data: tags.data.data, total: tags.data.total}
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
