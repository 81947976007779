import { publicClient } from '@/plugins/axios'
import { AxiosError } from 'axios'

class AuthResource {
    async signin (login) {
        try {
            const response = await publicClient.post('/auth/signin', login)
            return response.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async recoverPassword (passwordRequest){
        try {
            await publicClient.post('/auth/reset-password-request', passwordRequest)
            return true
        } catch (error) {
            if (error instanceof AxiosError) {
                if (error.status === 409) {
                    return 'messages.pendingRequest'
                }
            }
            console.warn(error)
            return null
        }
    }
    async resetPassword (resetPassword) {
        try {
            await publicClient.post('/auth/reset-password', resetPassword)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async createPublicUser(user) {
        try {
            return publicClient.post('/auth/signup', user)
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}

export default AuthResource
