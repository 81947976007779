import AbstractResource from '@/resources/abstract.resource'
import { UrlHelper } from '@/utils/urlHelper'


export default class MenuSectionResource extends AbstractResource {
    constructor () {
        super()
    }

    async createMenuSection (menuSection) {
        try {
            return this.client.post('/menu-section', menuSection)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async updateMenuSection (menuSection) {
        try {
            return this.client.patch(`/menu-section/${menuSection.id}`, menuSection)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async getMenuMenuSections (menuId, params) {
        try {
            const taverns = await this.client.get(`/menu/${menuId}/menu-sections`, { params: UrlHelper.buildUrlParams(params) })
            return taverns.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async deleteMenuSection (menuSectionId) {
        try {
            await this.client.delete(`/menu-section/${menuSectionId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
