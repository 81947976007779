import { adminClient, privateClient } from '@/plugins/axios'
import store from '@/store'

class AbstractResource {
    constructor () {
        if (store.getters.isAdmin) {
            this.client = adminClient
            return
        }
        if (store.getters.isUser) {
            this.client = privateClient
            return
        }
        throw new Error('You cannot pass!')
    }
}

export default AbstractResource
