<template>
  <SignupRequestList />
</template>

<script>
import SignupRequestList from '@/views/components/signupRequest/SignupRequestList.vue'

export default {
  name: 'SignupRequestPage',
  components: { SignupRequestList }
}
</script>

<style scoped>
</style>
