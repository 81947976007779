<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6>
        {{ $t('messages.signupRequest') }}
      </h6>
    </div>
    <div class="card-body">
      <ValidationForm
        v-slot="{errors}"
        :validation-schema="signupRequestSchema"
        type="POST"
        @submit="updateSignupRequest"
      >
        <div class="form-group">
          <p><label class="form-label">{{ $t('messages.name') }}:</label> {{ signupRequest.name }}</p>
          <p><label class="form-label">{{ $t('messages.email') }}:</label> {{ signupRequest.email }}</p>
          <p><label class="form-label">{{ $t('messages.date') }}:</label> {{ signupRequest.createdAt }}</p>
          <p><label class="form-label">{{ $t('messages.message') }}:</label></p>
          <p>{{ signupRequest.message }}</p>
        </div>
        <div
          v-if="admin"
          class="form-group"
        >
          <label class="form-label">{{ $t('messages.signupRequestStatus') }}</label>
          <Field
            v-model="signupRequestStatus"
            name="status"
            as="select"
            class="form-control"
            :value="signupRequest.status"
            :class="{'is-invalid':errors.signupRequestStatus}"
            :placeholder="$t('messages.signupRequestStatus')"
            :disabled="!admin"
            aria-label="Status"
          >
            <option
              v-for="item in signupRequestStatusList"
              :key="item"
              :value="item"
            >
              {{ $t('messages.status.' + item) }}
            </option>
          </Field>
          <ErrorMessage
            class="text-danger small"
            name="status"
          />
        </div>
        <div class="text-center row">
          <div class="col">
            <button
              type="submit"
              role="button"
              :disabled="!admin"
              class="my-4 mb-2 btn btn-primary"
            >
              <mdicon name="content-save-check-outline" />
              &nbsp;&nbsp;{{ $t('messages.save') }}
            </button>
            <a
              role="button"
              class="my-4 mb-2 mx-2 btn btn-danger"
              disabled
              @click="deleteSignupRequest"
            >
              <mdicon name="trashCanOutline" />
              &nbsp;{{ $t('messages.delete') }}
            </a>
            <a
              role="button"
              class="my-4 mb-2 mx-2 btn btn-secondary"
              @click="closeSignupRequest"
            >
              <mdicon name="close-outline" />
              &nbsp;{{ $t('messages.close') }}
            </a>
          </div>
        </div>
      </ValidationForm>
    </div>
  </div>
</template>

<script>
import { Form as ValidationForm, Field, ErrorMessage } from 'vee-validate'
import SignupRequest from '@/models/signupRequest'
import { SignupRequestStatus } from '@/utils/enums/signupRequestEnum'
import { object, string } from 'yup'

export default {
  name: 'ManageSignupRequest',
  components: { ValidationForm, Field, ErrorMessage },
  props: { signupRequest: SignupRequest },
  emits: ['update-signup-request', 'delete-signup-request', 'close-signup-request'],
  data () {
    const signupRequestSchema = object({
      status: string().optional()
    })
    console.log(SignupRequestStatus)
    return {
      signupRequestSchema,
      signupRequestStatusList: SignupRequestStatus
    }
  },
  computed: {
    signupRequestStatus: {
      get: function () {
        return this.signupRequest.status
      },
      set: function (status) {
        return status
      }
    },
  },
  methods: {
    deleteSignupRequest () {
      this.$emit('delete-signup-request', this.signupRequest)
    },
    updateSignupRequest (data) {
      let signupRequest = {
        status: data.status
      }
      this.$emit('update-signup-request', this.signupRequest.id, signupRequest)
    },
    closeSignupRequest () {
      this.$emit('close-signup-request')
    }
  }
}
</script>

<style scoped>
</style>
