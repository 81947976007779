<template>
  <UserList />
</template>

<script>
import UserList from '@/views/components/user/UserList.vue'
export default {
  name: 'AdminUsersPage',
  components: {
    UserList
  }
}
</script>
