export default class SignupRequest {
  constructor (data) {
    if (!data) {
      this.id = null
      this.name = null
      this.email = null
      this.message = null
      this.status = null
      this.createdAt = null
      return
    }

    this.id = data.id
    this.name = data.name
    this.email = data.email
    this.message = data.message
    this.status = data.status
    this.createdAt = data.createdAt
  }
}
