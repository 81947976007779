import AbstractResource from '@/resources/abstract.resource'
import { UrlHelper } from '@/utils/urlHelper'

export default class TimeTableResource  extends AbstractResource {
    constructor () {
        super()
    }

    async createTimeTable (timeTable) {
        try {
            return this.client.post('/time-table', timeTable)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async updateTimeTable (timeTable) {
        try {
            return this.client.patch(`/time-table/${timeTable.id}`, timeTable)
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async deleteTimeTable (timeTableId) {
        try {
            await this.client.delete(`/time-table/${timeTableId}`)
            return true
        } catch (error) {
            console.warn(error)
            return null
        }
    }

    async getTavernTimeTables (tavernId, params) {
        try {
            const timeTables = await this.client.get(`/tavern/${tavernId}/time-tables`, { params: UrlHelper.buildUrlParams(params) })
            return timeTables.data
        } catch (error) {
            console.warn(error)
            return null
        }
    }
}
