<template>
  <div class="card z-index-0">
    <div class="card-header text-center">
      <h6>
        {{ $t('messages.TavernQrCode', { tavernName: tavern.title }) }}
      </h6>
    </div>
    <div class="card-body">
      <div class="text-center row mb-5">
        <div
          class="col"
        >
          <VueQrcode
            id="qrCode"
            :value="value"
            :options="qrOptions"
          />
        </div>
      </div>
      <div class="text-center row">
        <div class="col">
          <button
            role="button"
            class="my-4 mb-2 btn btn-primary"
            @click="download()"
          >
            <mdicon name="cloud-download-outline" />
            &nbsp;&nbsp;{{ $t('messages.download') }}
          </button>
          <a
            role="button"
            class="my-4 mb-2 mx-2 btn btn-success"
            @click="close"
          >
            <mdicon name="close-outline" />
            &nbsp;{{ $t('messages.close') }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { toPng, toBlob } from 'html-to-image'
import { saveAs } from 'file-saver'
import Tavern from '@/models/tavern'

export default {
  name: 'TavernQR',
  components: { VueQrcode },
  props: { tavern: Tavern },
  emits: ['hide-forms'],
  data () {
    return {
      value: null,
      qrOptions: { width: 300 },
      downloading: false
    }
  },
  async mounted() {
    if (!this.tavern.slug) {
      await this.$notice.toast.warning(this.$t('messages.mustHaveSlug'))
    }
    this.value = this.getUrl(this.tavern)
  },
  methods: {
    getUrl (tavern) {
      if (tavern.slug === 'hamabost') {
        return 'https://www.hamabost.es'
      }
      if (tavern.slug === 'volatin701') {
        return 'https://www.volatin701.es'
      }
      return `https://sites.taverno.es/${this.tavern.slug}`
    },
    download () {
      this.downloading = true
      const templateRef = document.getElementById('qrCode')
      const options = { skipFonts: true }
      toBlob(templateRef, options).then((dataUrl) => {
        saveAs(dataUrl, 'my-node.png');

        // const link = document.createElement("a")
        // link.download = `${this.tavern.slug}-qrcode.png`
        // link.href = dataUrl
        // link.click()
        this.downloading = false
      }).catch((err) => {
        console.warn(err, templateRef);
        this.downloading = false
      })
    },
    close() {
      this.$emit('hide-forms')
    }
  }
}
</script>

<style scoped>
</style>
