<template>
  <TavernList />
</template>

<script>
import TavernList from '@/views/components/tavern/TavernList.vue'

export default {
  name: 'TavernsPage',
  components: {
    TavernList
  }
}
</script>
