<template>
  <footer class="footer py-5">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6 mb-lg-0 mb-4">
          <div class="copyright text-center text-sm text-muted text-lg-start">
            © Copyright <strong>taverno</strong>, a foconazo's site
          </div>
        </div>
        <div class="col-lg-6">
          <div class="row">
            <div class="col-md-6">
              <p class="mb-0">
                <a
                  :href="siteUrl"
                  class="nav-link text-success"
                  target="_blank"
                >{{ $t('messages.home') }}</a>
              </p>
              <p class="mb-0">
                <a
                  :href="siteUrl + 'privacy-policy'"
                  class="nav-link text-success"
                  target="_blank"
                >{{ $t('messages.privacyPolicy') }}</a>
              </p>
            </div>
            <div class="col-md-6">
              <p class="mb-0">
                <a
                  :href="siteUrl + 'terms-of-use'"
                  class="nav-link text-success"
                  target="_blank"
                >{{ $t('messages.termsOfUse') }}</a>
              </p>
              <p class="mb-0">
                <a
                  :href="siteUrl + 'cookies-policy'"
                  class="nav-link pe-0 text-success"
                  target="_blank"
                >{{ $t('messages.cookiesPolicy') }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  data () {
    return {
      siteUrl: process.env.VUE_APP_SITE_URL
    }
  }
}
</script>

<style scoped>
</style>
