<template>
  <TagList />
</template>

<script>
import TagList from '@/views/components/tag/TagList.vue'

export default {
  name: 'TagsPage',
  components: {
    TagList
  }
}
</script>
