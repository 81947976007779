<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="card">
        <contentPlaceholder v-if="loading" />
        <UpsertUser
          v-else
          :user="user"
          @update-user="updateUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import contentPlaceholder from '@/components/layout/ContentPlaceholder.vue'
import UpsertUser from '@/views/components/user/UpsertUser.vue'
import UserResource from '@/resources/user.resource'
import User from '@/models/user'

export default {
  name: 'ProfilePage',
  components: {
    UpsertUser,
    contentPlaceholder
  },
  data () {
    return {
      loading: true,
      user: null,
      userResource: new UserResource()
    }
  },
  mounted () {
    const userId = this.$route.params?.userId ?  this.$route.params?.userId : null
    this.selectUser(userId)
  },
  methods: {
    async selectUser(userId) {
      const response = await this.userResource.getUser(userId)
      if (response) {
        this.loading = false
        this.user = new User(response)
      }
    },
    async updateUser (userData) {
      try {
        const response = await this.userResource.updateUser(userData)
        if (!response) {
          await this.$notice.alert(this.$t('messages.errorMessage'))
          return false
        }
        await this.$notice.toast.info(this.$t('messages.updateMessage'))
      } catch (e) {
        await this.$notice.alert(this.$t('messages.errorMessage'))
        console.warn(e)
      }
    },
  }
}
</script>
